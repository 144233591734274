'use strict';

angular.module("ufcw")

.config(function ($stateProvider, $urlRouterProvider) {

    $urlRouterProvider.otherwise('/signin');

    $stateProvider
      .state('signin', {
        url: '/signin',
        templateUrl: '../views/signin.html',
        controller: 'SigninController as signinCtrl'
      })
      .state('home', {
        url: '/home',
        templateUrl: '../views/home.html',
        controller: 'HomeController as homeCtrl'
      })
      .state('reservaactividad/:id', {
        url: '/reservaactividad',
        templateUrl: '../views/reservaactividad.html',
        controller: 'ReservaactividadController as raCtrl'
      })
      .state('welcome/:token', {
        url: '/welcome',
        templateUrl: '../views/welcome.html',
        controller: 'WelcomeController as welcomeCtrl'
      })
      .state('recovery', {
        url: '/recovery',
        templateUrl: '../views/recovery.html',
        controller: 'RecoveryController as recoveryCtrl'
      })
      .state('setpwd', {
        url: '/setpwd',
        templateUrl: '../views/setpwd.html',
        controller: 'RecoveryController as recoveryCtrl'
      })
      .state('misreservas', {
        url: '/misreservas',
        templateUrl: '../views/misreservas.html',
        controller: 'MisreservasController as mrCtrl'
      })
      .state('notice', {
        url: '/notice',
        templateUrl: '../views/notice.html',
        controller: 'NoticeController as ntcCtrl'
      })
      .state('horarios', {
        url: '/horarios',
        templateUrl: '../views/horarios.html',
        controller: 'HorariosController as fcCtrl'
      })
      .state('fronthorarios/:id', {
        url: '/fronthorarios',
        templateUrl: '../views/fronthorarios.html',
        controller: 'FronthorariosController as fhCtrl'
      })
      .state('reglas', {
        url: '/reglas',
        templateUrl: '../views/reglas.html',
        controller: 'ReglasController as reCtrl'
      })
      .state('advancedsearch', {
        url: '/advancedsearch',
        templateUrl: '../views/advancedsearch.html',
        controller: 'AdvancedsearchController as asCtrl'
      })                
      .state('qr', {
        url: '/qr',
        templateUrl: '../views/qr.html',
        controller: 'QrController as qrCtrl'
      })    
      .state('fixedactivities', {
        url: '/fixedactivities',
        templateUrl: '../views/fixedactivities.html',
        controller: 'FixedactivitiesController as fxCtrl'
      })          
  })
  .config(function ($qProvider) {
    $qProvider.errorOnUnhandledRejections(false);
})
.config(['$locationProvider', function($locationProvider) {
  $locationProvider.html5Mode(true);
  $locationProvider.hashPrefix('');
}])
.config(['$translateProvider', function($translateProvider) {
  $translateProvider.translations('en', en_lang);
  $translateProvider.translations('es', es_lang);
  $translateProvider.preferredLanguage('es');
  $translateProvider.useSanitizeValueStrategy('escape')

}]);
