'use strict';

angular.module('ufcw')
  .controller('MisreservasController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,$location,EnvConfig,$translate) {
    var vm = this;
    vm.errorMsg = null;
    vm.document = "";
    // var user  = $localstorage.getObject('user');

    // vm.users = $localstorage.getObject('dependents');
    // vm.has_dependent = (vm.users.length === 0)?false:true;
    // vm.users.push(user);

    // $rootScope.user = user;
    // $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("reserve.my_reserves");

    var days = [$translate.instant('days.sunday'), $translate.instant('days.monday'), $translate.instant('days.tuesday'), $translate.instant('days.wednesday'), $translate.instant('days.thursday'), $translate.instant('days.friday'), $translate.instant('days.saturday')];
    var months = [$translate.instant('months.january'), $translate.instant('months.february'), $translate.instant('months.march'), $translate.instant('months.april'), $translate.instant('months.may'), $translate.instant('months.june'), $translate.instant('months.july'),$translate.instant('months.august'),$translate.instant('months.september'),$translate.instant('months.october'),$translate.instant('months.november'),$translate.instant('months.december')];

    vm.load = function(){
      //get next reservations for user



      var params = "?userid="+vm.document;
      if(vm.from && vm.to){
        params = "&from="+vm.from+"&to="+vm.to;
      }
      Request.get('reservation/'+params,true,function(response) {
        if(response.status !== 0){
          toaster.pop('info', "", $translate.instant('api.'+response.description));
          vm.reservas = [];
          vm.u_name = "";
        }else{
          vm.reservas = response.description.reservations;
          vm.u_name = response.description.userName;
          vm.reservas.map(function(item){
            item.reservationdate = item.reservationdate.replace(/-/g, '/');
            
            var d = new Date(item.reservationdate);
            var dayName = days[d.getDay()];
            var dayNumber = d.getDate();
            var monthName = months[d.getMonth()];
            item.day = dayName +" "+ dayNumber+ " " + $translate.instant('general.of') + " "+ monthName;
          });
          vm.no_reservations = (vm.reservas.length === 0)?true:false;
        }
      });
    };

    // vm.load();

    vm.sendNotification = function(id,action){
      var data = {"type":"email","action":action,"reservationId":id};
      Request.post('notification/',true,data,function() {
      });
    };

    vm.cancelReservation = function (completeActivity) {

      // $log.$log(completeActivity,"chuchu");
      // return;

      Request.delete('reservation/?id='+completeActivity.id+"&uid="+completeActivity.u_id,true,function(response) {
        if(response.status !== 0){
          toaster.pop('error', "", $translate.instant('api.'+response.description));
        }else{
          toaster.pop('success', "", $translate.instant("reserve.canceled_ok"));
          vm.document = "";
          // vm.load();
          vm.sendNotification(completeActivity.id,'cancel');
          $location.path('/home');  

        }
      });
    };

    // MODAL THINGS
    vm.animationsEnabled = true;
    
    vm.open = function (size, parentSelector) {

      
  


      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;


      


      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'confirmareserva.html',
        controller: 'ModalReservaController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          title: function () {
            return vm.title;
          },
          day: function () {
            return vm.activity.day;
          },
          dependents: function () {
            return vm.dependents;
          },
          action: function () {
            return vm.action;
          },
          activity: function () {
            return vm.activity;
          },
          showInvitation: function () {
            return vm.showInvitation;
          },
          id: function () {
            return vm.id;
          }
        }
      });
  
      modalInstance.result.then(function (result) {
          if(result.status === 'OK'){
            vm.activity = result.activity;
            vm.cancelReservation(vm.activity);
          }
      }, function () {
  
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };



    vm.cancelRes = function(activity){

  
      vm.activity = activity;
      vm.at = vm.activity.id;
      vm.id ="";
      vm.moredays = (vm.showNextDay)?0:1;
      vm.title = $translate.instant("reserve.cancel");
      vm.showInvitation = false;
      vm.action = "CANCEL";
      

      vm.open();
    };

    vm.openGuests = function (size, parentSelector) {
      var parentElem = parentSelector ? 
        angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
      var modalInstance = $uibModal.open({
        animation: vm.animationsEnabled,
        ariaLabelledBy: 'modal-title',
        ariaDescribedBy: 'modal-body',
        templateUrl: 'guests.html',
        controller: 'ModalEditGuestsController',
        controllerAs: 'modalCtrl',
        size: size,
        backdrop: 'static',
        keyboard: false,
        appendTo: parentElem,
        resolve: {
          activity: function () {
            return vm.activity;
          }
        }
      });
  
      modalInstance.result.then(function () {
      }, function () {
        // $log.info('Modal dismissed at: ' + new Date());
      });
    };

    vm.showGuests = function(activity){
      if(activity.allowGuest === 1 && angular.isString(activity.description)){
        activity.guests = activity.description.split(',');
        delete activity.description;
      }
      vm.activity = activity;
      vm.activity.editGuests = true;
      vm.openGuests();
    };

  });

  // angular.module('ufcw').controller('ModalReservaController', function ($uibModalInstance,$log,title,day,dependents,action,activity,showInvitation,toaster,$translate) {
  //   var vm = this;


  //

  //   vm.title = title;
  //   vm.activity = activity;
  //   vm.day = day;
  //   vm.dependents = dependents;
  //   vm.action = action;
  //   vm.showInvitation = showInvitation;
  //   // vm.document = "";

  //   vm.ok = function () {



  //     // if( vm.document != '') {

  //       try {
  //         if(vm.action === 'DEPENDENT' && !vm.selDependent){
  //           throw $translate.instant("reserve.must_select_dependent");
  //         }
  //         if(vm.inv1){
  //           vm.activity.description += ""+vm.inv1;
  //         }
  //         if(vm.inv2){
  //           vm.activity.description += ", "+vm.inv2;
  //         }
  //         if(vm.inv3){
  //           vm.activity.description += ", "+vm.inv3;
  //         }
  
  
  //         vm.activity.ucode = vm.document;
  //         $uibModalInstance.close({dependent: vm.selDependent, activity:vm.activity, status: 'OK'});
  //       } catch (error) {
  //         toaster.pop('error', "", error);
  //       }

  //     // }else{
  //     //   toaster.pop('info', "", "Ingrese documento o número de socio");
  //     // }
      
  //   };
  
  //   vm.cancel = function () {
  //     $uibModalInstance.dismiss('cancel');
  //   };
  // });

  // angular.module('ufcw').component('modalComponent', {
  //   templateUrl: 'confirmareserva.html',
  //   bindings: {
  //     resolve: '<',
  //     close: '&',
  //     dismiss: '&'
  //   },
  //   controller: function () {
  //     var vm = this;
      


  //     vm.$onInit = function () {
  //       vm.title = vm.resolve.title;
  //       vm.activity = vm.resolve.activity;
  //       vm.day = vm.resolve.day;
  //       vm.dependents = vm.resolve.dependents;
  //       vm.action = vm.resolve.action;
  //       vm.showInvitation = vm.resolve.showInvitation;
  //     };
  
  //     vm.ok = function () {
  //       vm.close({$value: 'OK'});
  //     };
  
  //     vm.cancel = function () {
  //       vm.dismiss({$value: 'cancel'});
  //     };
  //   }
  // });
