var en_lang = {
    "signin":{
        "email_required": "Email required",
        "email_invalid": "Email invalid",
        "pwd_required": "Password required",
        "do_not_register": "Not signed in?",
        "create_account": "Create account",
        "soon": "You will be able to make reservations soon. Thanks for your patience!"
    },
    "recovery":{
        "text": "No problem enter your email addres and we will send you a code to reset your password."
    },
    "rest": {
        "session_expired": "Session expired, try again"
    },
    "general":{
        "view_schedule": "See Timetable",
        "forgot_your_pwd": "Lost password?",
        "email": "Email",
        "password": "Password",
        "developed_by": "Developed by",
        "back": "Back",
        "next_day": "Next day",
        "my_profile": "My Profile",
        "my_bookings": "My Reservations",
        "rules": "Rules",
        "covid_notice": "Report COVID-19",
        "session_close": "Sign Out",
        "reserve": "Book now",
        "page_title": "Reservation Manager",
        "contact_us": "Contact Us",
        "all_activities": "All Activities",
        "all_categories": "All Categories",
        "all_people": "All Persons",
        "all_ages": "All Ages",
        "member_num": "Membership number",
        "member_id": "Identification number",
        "dob": "Date of Birth",
        "phone": "Phone number",
        "my_family_group": "My family group",
        "yes": "YES",
        "no": "NO",
        "of": "from ",
        "activities": "Activities",
        "messages": "My Messages",
        "carnetExpiration": "Venc. C. Salud",
        "payExpiration": "Venc. de Pago",
        "expiration_title": "Aviso de Vencimiento",
        "carnetExpiration_message": "Le informamos que su C. Salud vence el ",
        "payExpiration_mensual_message": "Le informamos que su Mensualidad venció el ",
        "payExpiration_anual_message": "Le informamos que su Anualidad vence el ",
        "documents": "Documentos",
        "buy_cuponeras": "Comprar cuponeras",
        "cuponeras": "Cuponeras"
    },
    "advsearch":{
        "filter": "Filter",
        "schedule": "Schedule",
        "age": "age",
        "no_activities": "No activities for the applied filter",
        "title": "Advanced Search"
    },
    "button": {
        "send": "Send",
        "signin": "Sign In",
        "search": "Search",
        "cancel": "Cancel",
        "reserve": "Book Time",
        "register": "Register",
        "enter": "Enter",
        "notify": "Notify",
        "close": "Close",
        "save": "Save",
        "confirm": "Book Time",
        "ok": "Ok"
    },
    "days": {
        "mon": "MON",
        "tue": "TUE",
        "wed": "WED",
        "thu": "THU",
        "fri": "FRI",
        "sat": "SAT",
        "sun": "SUN",
        "monday": "Monday",
        "tuesday": "Tuesday",
        "wednesday": "Wednesday",
        "thursday": "Thursday",
        "friday": "Friday",
        "saturday": "Saturday",
        "sunday": "Sunday"
    },
    "months": {
        "january": "January",
        "february": "February",
        "march": "March",
        "april": "April",
        "may": "May",
        "june": "June",
        "july": "July",
        "august": "August",
        "september": "September",
        "october": "October",
        "november": "November",
        "december": "December"
    },
    "reserve": {
        "reserve": "Book",
        "gender": "Gender",
        "activity": "Hospital",
        "place": "Place",
        "status": "Status",
        "date": "Date",
        "hour": "Time",
        "member": "Member",
        "assistance": "Assistance",
        "info": "Info",
        "guests": "Guests",
        "view_guests": "View Guests",
        "view_my_waitinglist": "My waiting lists",
        "view_my_history": "Reservation History",
        "reserved": "RESERVED",
        "canceled": "CANCELED",
        "activity_suspended": "ACTIVITY SUSPENDED",
        "does_not_have_reserve" : "No reservations",
        "info_activity" : "Activity info",
        "add_to_waitinglist" : "Add to waiting list",
        "available_places": "places available",
        "unlimited_places": "Unlimited places",
        "there_not": "There's not",
        "view_class": "Watch Class",
        "reserve_for_family": "Book time for family member",
        "no_activities_for_today": "No activities today",
        "actions": "Actions",
        "remove_from_waitinglist": "Remove from waiting list",
        "family_assign": "Assign family member",
        "member_num_id": "Membership/ID Number",
        "guests_to": "Guest to ",
        "for_me": "For me",
        "waiting_text": "Find out when there is availability.",
        "history": "Reservation History",
        "status_reserved": "Reserved",
        "status_canceled": "Canceled",
        "status_active": "Active",
        "my_reserves": "My Reservations",
        "canceled_ok": "Reservation canceled.",
        "cancel": "Cancel reservation",
        "must_select_dependent": "Must pick a family member",
        "title": "Schedule of activities",
        "success": "Reservation succesfully made",
        "modal_title_confirm": "Confirm reservation",
        "modal_title_cancel": "Cancel reservation",
        "add_to_waitinglist": "Add to waiting list",
        "waitinglist_added": "Successfully added to waiting list"
    },
    "maintenance": {
        "title": "We are under maintenance!",
        "msg": "Thanks for your patience.",
        "msg2": "Please try again later."
    },
    "setpwd": {
        "title": "Passsword recovery assistant.",
        "sub_title": "Please enter the code sent by email, and then enter your password.",
        "code": "Code",
        "required_code": "Code required",
        "confirm_pwd": "Retype password",
        "pwd_not_match": "Password dont match"
    },
    "signup": {
        "title": "Member Signup",
        "sub_title": "Please enter your information and press signup.",
        "sub_title2": "You will get a email to confirm you own your email account.",
        "member_num": "Membership number",
        "member_num_required": "Membership number required",
        "member_id_required": "Identification number required",
        "member_id_invalid": "Please enter Identification number without points or slashes",
        "dob": "Date of Birth",
        "dob_required": "Date of Birth required",
        "cellphone": "Mobile number",
        "cellphone_required": "Mobile number required",
        "confirm_pwd_required": "Retype password required",
        "success": "User registered successfully! Please check your email to complete the registration process."
    },
    "welcome": {
        "title": "Congratulations, account created successfully!",
        "sub_title": "Yo can signin and book your activities!",
        "success": "User created successfully!"
    },
    "privacy": {
        "title": "Privacy Policy"
    },
    "notice": {
        "title": "Report COVID-19",
        "text": "When you report COVID positive we will start our internal protocols. 1st calling you to the provided phone to confirm informaiton. After we will alert members which shared acitivties with you that they where in contact with a COVID positive. We warrant confidentiality of your information."
    },
    "rules": {
        "title": "Rules"
    },
    "confirm": {
        "title": "I confirm COVID-19 positive",
        "text": "Are you sure to confirm COVID-19 positive and notify us?"
    },
    "profile": {
        "title": "My profile",
        "current_pwd": "Current Password",
        "current_pwd_invalid": "Invalid Current password",
        "current_pwd_placeholder": "Enter last password",
        "new_pwd": "New Password",
        "new_pwd_invalid": "Invalid New Password",
        "new_pwd_placeholder": "Enter New Password",
        "saved": "Profile updated.",
        "phone_invalid": "Wrong Phone Number",
        "select_lang": "Select language",
        "lang": "Language"
    },
    "showinfo": {
        "about": "About"
    },
    "waitinglist": {
        "title": "Waiting lists",
        "removed": "Removed from waiting list."
    },
    "lang": {
        "es": "Spanish",
        "en": "English"
    },
    "message": {
        "open": "Open",
        "delete": "Delete message",
        "you_dont_have": "You don't have any message to show",
        "received":"Received",
        "at": "at"
    },
    "api": {
        "general": {
            "days": {
                "monday": "Monday",
                "tuesday": "Tuesday",
                "wednesday": "Wednesday",
                "thursday": "Thursday",
                "friday": "Friday",
                "saturday": "Saturday",
                "sunday": "Sunday"
            },
            "month": {
                "january": "January",
                "february": "February",
                "march": "March",
                "april": "April",
                "may": "May",
                "june": "June",
                "july": "July",
                "august": "August",
                "september": "September",
                "october": "October",
                "november": "November",
                "december": "December"
            }
        },
        "api-admin": {
            "activitiescanceled": {
                "id_required": "Activity ID required",
                "invalid_id": "Invalid ID",
                "date_required": "Date required",
                "user_id_required": "User ID required",
                "cancel_reason": "Cancelation reason is required",
                "list_user_required": "The list of members is required"
            },
            "activitycategory": {
                "id_required": "Category ID required",
                "invalid_id": "Invalid ID",
                "was_deleted": "Category was deleted",
                "name_required": "Name required"
            },
            "activity": {
                "id_required": "Activity ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required",
                "category_id_required": "Category required",
                "company_id_required": "Company required",
                "status_required": "Status required"
            },
            "activitytime": {
                "user_required": "User required",
                "invalid_conf": "Invalid configuration",
                "invalid_activity": "Activity ID invalid",
                "activity_required": "Activity ID required",
                "starttime_required": "Start time required",
                "endtime_required": "End time required"
            },
            "adminuser": {
                "invalid_action": "Invalid action",
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "company_id_required": "Company required",
                "user_type_required": "User type required",
                "invalid_user_type": "Invalid user type",
                "user_email_required": "User required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "email_exist": "Email already exist",
                "user_deleted": "User deleted"
            },
            "assistance": {
                "list_user_required": "Members list required",
                "list_check_user": "List saved, please double check the attendees, there where updates after opening this page.",
                "list_ok": "List entered"
            },
            "fixreservation": {
                "user_id_required": "User ID required",
                "activity_required": "Activity ID required",
                "date_required": "Date required",
                "invalid_user_id": "Invalid user",
                "invalid_activity": "Invalid Activity",
                "reservation_exists": "Reservation already exists"
            },
            "holiday": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "deleted_ok": "Deleted OK",
                "name_required": "Name required",
                "year_required": "Year required",
                "month_required": "Month required",
                "day_required": "Day required"
            },
            "location": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required"
            },
            "message": {
                "no_msg": "No messages",
                "msg_not_exist": "Message doesn't exist",
                "saved": "Message saved",
                "invalid_id": "Invalid ID",
                "read": "Message read",
                "msg_deleted": "Message deleted"
            },
            "product": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "deleted_ok": "Deleted OK",
                "name_required": "Name required",
                "price_required": "Price required",
                "currency_required": "Currency required"
            },
            "recovery": {
                "email_required": "User mail required",
                "invalid_email": "Invalid user mail",
                "invalid_user_id": "Invalid user",
                "email_sent": "Mail sent",
                "code_required": "Recovery code required",
                "pwd_required": "Password required",
                "invalid_code": "Invalid code",
                "expired_code": "Expired code",
                "pwd_change_ok": "Password updated",
                "pwd_recovery": "Password recovery"
            },
            "report": {
                "type_required": "Type required",
                "date_from_required": "Start date required",
                "date_to_required": "End date required"
            },
            "reservation": {
                "type_required": "Type required",
                "invalid_user_id": "Invalid user",
                "id_required": "ID required",
                "error_check_attendance": "There was a problem with the reservation, couldn't mark assistance",
                "error_cancel_attendance": "There was a problem with the reservation, it couldn't be canceled",
                "user_id_required": "User ID required",
                "activity_id_required": "Activity ID required",
                "invalid_conf": "Invalid configuration",
                "user_deleted": "Member unsubscribed correctly",
                "reservation_not_allowed": "Dear user, you are not authorized to make reservations. Reason:",
                "invalid_schedule": "Invalid schedule",
                "invalid_reservation": "Invalid reservation",
                "generic_error": "An error occurred, please close the page and try again.",
                "invalid_activity": "Invalid Activity",
                "invalid_category": "Invalid Category",
                "invalid_Location": "Invalid Location",
                "no_more_places": "There are no more spots for this activity, try to book another time or another activity ...",
                "invalid_user_cat": "Invalid user category",
                "dob_error": "There is a problem with your date of birth, please contact administration to fix it",
                "out_of_age": "This activity isn't for your age.",
                "reservation_same_time": "You already have reservations at the same time.",
                "reservation_exists": "Reservation already exist",
                "penalization_msg_start": "You have a penalty of",
                "penalization_msg_end": "hours",
                "same_activity_msg_start": "Can't book ",
                "same_activity_msg_end": "consecutive activities of the same category",
                "max_per_day_msg_start": "You can't book more than",
                "max_per_day_msg_end": "activities same day",
                "not_allowed_only_members": "Acción no permitida para su tipo de usuario."
            },
            "responsible": {
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "company_id_required": "Company required",
                "user_email_required": "Mail required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user"
            },
            "signin": {
                "user_id_required": "User ID required",
                "pwd_required": "Password required",
                "invalid_user_id": "Invalid username or password",
                "invalid_user": "Invalid user"
            },
            "signup": {
                "user_email_required": "User required",
                "company_id_required": "Company required",
                "invalid_user_email": "Invalid user",
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "pwd_required": "Password required",
                "user_type_required": "User type required",
                "invalid_user_type": "Invalid user type",
                "user_already_registered": "Email already registered",
                "invalid_user": "Invalid user",
                "reg_confirmation": "Confirmation of registration",
                "token_required": "User required"
            },
            "uploadimage": {
                "image_required": "Image required",
                "image_name_required": "Image name required",
                "file_not_image": "File is not an image",
                "invalid_type": "Invalid image type",
                "error_save": "Error saving image",
                "saved_ok": "Image saved"
            },
            "usercategory": {
                "id_required": "User ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required",
                "age_from_required": "From age required",
                "age_to_required": "To age required"
            },
            "user": {
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "company_id_required": "Company required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "invalid_email": "Invalid mail"
            },
            "usertype": {
                "id_required": "ID required",
                "invalid_id": "Invalid ID",
                "name_required": "Name required"
            },
            "whassistance": {
                "user_dont_exist": "User does not exist",
                "user_inactive": "Inactive user",
                "not_activities_today": "You have no activities scheduled today.",
                "assitant_error": "There was a problem with the reservation, couldn't check the attendance"
            },
            "whuser": {
                "user_name_required": "User name required. Received:",
                "user_lastname_required": "User last name required. Received:",
                "user_extid_required": "User number required. Received:",
                "company_id_required": "Company required. Received:",
                "doc_required": "User number required. Received:",
                "gender_required": "Company required. Received:"
            },
            "notification": {
                "id_required": "ID required",
                "subject_required": "Subject required",
                "title_required": "Title required",
                "text_required": "Body required",
                "invalid_user": "Invalid user",
                "invalid_main_user": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_conf": "Invalid configuration",
                "email_sent": "Email sent",
                "invalid_schedule": "Invalid schedule",
                "invalid_activity": "Invalid Activity",
                "invalid_reservation": "Invalid ID",
                "confirm_reservation": "Reservation confirmation",
                "you_cancel": "You cancel",
                "we_cancel": "We cancel",
                "reason": "Reason:",
                "reservation_cancel": "Cancel"
            }
        },
        "api": {
            "activitytime": {
                "invalid_conf": "Invalid configuration",
                "user_required": "User required",
                "activity_required": "Activity required",
                "invalid_user": "Invalid user"
            },
            "email": {
                "id_required": "ID required",
                "subject_required": "Subject required",
                "title_required": "Title required",
                "text_required": "Body required",
                "invalid_user": "Invalid user",
                "invalid_main_user": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_conf": "Invalid configuration",
                "email_sent": "Email sent"
            },
            "message": {
                "wrong_user": "Wrong user",
                "msg_not_exist": "Message doesn't exist",
                "was_an_error": "An error has occurred",
                "msg_saved": "Message saved",
                "invalid_id": "Invalid ID",
                "msg_read": "Message read"
            },
            "notification": {
                "id_required": "ID required",
                "subject_required": "Subject required",
                "title_required": "Title required",
                "text_required": "Body required",
                "invalid_user": "Invalid user",
                "invalid_main_user": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_conf": "Invalid configuration",
                "email_sent": "Email sent",
                "invalid_schedule": "Invalid schedule",
                "invalid_activity": "Invalid Activity",
                "invalid_reservation": "Invalid ID"
            },
            "recovery": {
                "email_required": "User mail required",
                "invalid_email": "Invalid user mail",
                "invalid_user_id": "Invalid user",
                "email_sent": "Mail sent",
                "code_required": "Recovery code required",
                "pwd_required": "Password required",
                "invalid_code": "Invalid code",
                "expired_code": "Expired code",
                "pwd_change_ok": "Password updated",
                "not_access": "Disabled user, contact administration"
            },
            "reservation": {
                "type_required": "Type required",
                "invalid_user_id": "Invalid user",
                "id_required": "ID required",
                "user_id_required": "User ID required",
                "activity_id_required": "Activity ID required",
                "invalid_conf": "Invalid configuration",
                "user_deleted": "Unsubscribed user",
                "reservation_not_allowed": "Dear member, you are not authorized to make reservations. Reason:",
                "invalid_schedule": "Invalid schedule",
                "invalid_reservation": "Invalid reservation",
                "generic_error": "An error occurred, please close the page and try again.",
                "invalid_activity": "Invalid Activity",
                "invalid_category": "Invalid Category",
                "invalid_Location": "Invalid Location",
                "no_more_places": "There are no more places for this activity, try to book another time or another activity ...",
                "invalid_user_cat": "Invalid user category",
                "dob_error": "There is a problem with your date of birth, please contact administration to fix it",
                "out_of_age": "This activity is not for your age.",
                "reservation_same_time": "You already have reservations whose schedule coincides with other activities.",
                "reservation_exists": "Reservation already exists",
                "invalid_main_user_id": "Invalid main user",
                "invalid_action": "Invalid action",
                "invalid_user_category": "Your category doesn't allow you to book this activity",
                "penalization_msg_start": "You have a penalty of",
                "penalization_msg_end": "hours",
                "max_actives_reservation_msg_start": "You can't have more than",
                "max_actives_reservation_msg_end": " simultaneous reservations",
                "same_activity_msg_start": "Can't book ",
                "same_activity_msg_end": "consecutive activities of the same category",
                "max_per_day_msg_start": "You can't book more than",
                "max_per_day_msg_end": "activities the same day",
                "invalid_id": "Invalid ID",
                "cant_cancel_msg": "Sorry, the time to cancel this reservation was expired",
                "cancel_ok": "Reservation canceled",
                "free_place_msg": "Free spot alert",
                "free_place_body_a": "A spot was released for the class",
                "free_place_body_b": "from",
                "free_place_body_c": "to",
                "free_place_body_d": "for the day"
            },
            "signin": {
                "user_id_required": "User ID required",
                "pwd_required": "Password required",
                "invalid_user_id": "Invalid username or password",
                "dibaled_user_msg": "Disabled user, remember to check your email to validate it. If you have already done and can't log in, contact administration.",
                "invalid_conf": "Invalid configuration",
                "invalid_user_type": "Invalid user type"
            },
            "signup": {
                "company_id_required": "Company required",
                "invalid_user_email": "Invalid user",
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "pwd_required": "Password required",
                "user_type_required": "User type required",
                "invalid_user_type": "Invalid user type",
                "user_already_registered": "Email already registered",
                "invalid_user": "Invalid user",
                "token_required": "User required",
                "user_email_required": "User email required",
                "invalid_email": "Invalid email",
                "invalid_conf": "Invalid configuration",
                "deleted_user": "Diabled user",
                "wrong_user_data": "Wrong user info",
                "confirmation": "Registration confirmation",
                "dibaled_user_msg": "Disabled user, remember to check your email to validate it. If you have already done and can't log in, contact administration."
            },
            "user": {
                "user_name_required": "User name required",
                "user_lastname_required": "User last name required",
                "user_extid_required": "User number required",
                "company_id_required": "Company required",
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "invalid_email": "Invalid mail",
                "invalid_action": "Invalid action",
                "user_email_required": "User email required",
                "user_already_registered": "Email already exists",
                "last_pwd_invalid": "Invalid old password entered",
                "user_deleted": "User deleted"
            },
            "waitinglist": {
                "user_id_required": "User ID required",
                "invalid_user_id": "Invalid user",
                "activity_id_required": "Activity ID required",
                "invalid_schedule": "Invalid schedule",
                "user_already_exist": "You are already on the list",
                "user_added": "User added to waiting list",
                "invalid_reservation_id": "Invalid ID",
                "reservation_id_required": "Invalid ID",
                "invalid_action": "Invalid action",
                "invalid_main_user_id": "Invalid main user",
                "user_removed_from_list": "Member removed from waiting list"
            }
        }
    }
  };