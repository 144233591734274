'use strict';

angular.module('ufcw')
  .controller('QrController', function ($rootScope,$localstorage,$translate) {
    var vm = this;
    vm.errorMsg = null;

    vm.user  = $localstorage.getObject('user');


    
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("Codigo de Ingreso");



    

  });