'use strict';

angular.module('ufcw')
  .controller('HomeController', function ($log,Request,$localstorage,$state,$rootScope,toaster,$uibModal,$document,EnvConfig,$window,$translate) {
    var vm = this;

    
    vm.errorMsg = null;
    var user  = $localstorage.getObject('user');
    // var config  = $localstorage.getObject('config');
    
    $rootScope.user = user;
    $rootScope.headLander = true;
    $rootScope.titulopagina = $translate.instant("general.activities");

    //get activities for user category
    Request.get('activitycategory',true,function(response) {
      if(response.status !== 0){
        toaster.pop('info', "", $translate.instant('api.'+response.description));
      }else{
        vm.activities = response.description;
        vm.activities.map(function(item){
          item.icon = (item.icon)?EnvConfig.URL_IMAGES+item.icon:EnvConfig.URL_IMAGES+'icono_calendario.png';
          item.description = (item.description)?angular.fromJson(item.description):item.description;
        });
      }
    });

    //CUPONERAS
    // Request.get('usercuponera',true,function(response) {
    //   if(response.status !== 0){
    //     toaster.pop('info', "", $translate.instant('api.'+response.description));
    //   }else{
    //     vm.cuponeras = response.description;
    //     if($rootScope.user.userAccess === "CUPONERA" && vm.cuponeras.length === 0){
    //       $state.go('comprarcuponera');
    //     }
    //     vm.cuponeras.map(function(item){
    //       item.usercuponeraCreated = (item.usercuponeraCreated)? new Date(item.usercuponeraCreated):item.usercuponeraCreated;
    //     });
    //   }
    // });

    // //FIXED ACTIVITIES
    // Request.get('fixedactivities',true,function(response) {
    //   if(response.status !== 0){
    //     toaster.pop('info', "", $translate.instant('api.'+response.description));
    //   }else{
    //     vm.fixedActivities = response.description;
    //     //if($rootScope.user.userAccess === "CUPONERA" && vm.cuponeras.length === 0){
    //     //  $state.go('comprarcuponera');
    //     //}
    //     //vm.cuponeras.map(function(item){
    //     //  item.usercuponeraCreated = (item.usercuponeraCreated)? new Date(item.usercuponeraCreated):item.usercuponeraCreated;
    //    // });
    //   }
    // });

  // MODAL
  vm.animationsEnabled = true;

  // vm.open = function (size, parentSelector) {
  //   var tam = (size)?size:'lg';
  //   var parentElem = parentSelector ?
  //     angular.element($document[0].querySelector('.modal-demo ' + parentSelector)) : undefined;
  //   var modalInstance = $uibModal.open({
  //     animation: vm.animationsEnabled,
  //     ariaLabelledBy: 'modal-title',
  //     ariaDescribedBy: 'modal-body',
  //     templateUrl: 'initialmsg.html',
  //     controller: 'ModalInitialMsgController',
  //     controllerAs: 'modalCtrl',
  //     backdrop: 'static',
  //     keyboard: false,
  //     size: tam,
  //     appendTo: parentElem,
  //     resolve: {
  //       msg: function () {
  //         return vm.msg;
  //       }
  //     }
  //   });

  //   modalInstance.result.then(function () {
  //     }, function () {
  //   });
  // };

  // vm.showInit = function(){
  //   var config = $localstorage.getObject('config');
  //   vm.msg = config.initialMsg;
  //   vm.open();
  // };

  // if(vm.show_msg === 'true'){
  //   vm.showInit();
  // }


  });

  // angular.module('ufcw').controller('ModalInitialMsgController', function ($uibModalInstance,$log,msg,$localstorage,$sce) {
  //   var vm = this;
  //   vm.msg = $sce.trustAsHtml(msg);

  //   vm.ok = function () {
  //     $uibModalInstance.close({status: 'OK'});
  //   };

  //   vm.cancel = function () {
  //     $localstorage.set('showMsg',false);
  //     $uibModalInstance.dismiss('cancel');
  //   };

  // });

//   angular.module('ufcw').component('modalComponent', {
//   templateUrl: 'initialmsg.html',
//   bindings: {
//     resolve: '<',
//     close: '&',
//     dismiss: '&'
//   },
//   controller: function () {
//     var vm = this;

//     vm.$onInit = function () {
//       vm.msg = vm.resolve.msg;
//     };

//     vm.ok = function () {
//       vm.close({$value: 'OK'});
//     };

//     vm.cancel = function () {
//       vm.dismiss({$value: 'cancel'});
//     };
//   }
// });



