'use strict';

angular.module('ufcw')

.controller('main', ['$scope','$rootScope','$state','$stateParams','$log','$localstorage','EnvConfig','$translate','Request', function($scope,$rootScope,$state,$stateParams,$log,$localstorage,EnvConfig,$translate,Request) {
  $rootScope.footer_app_name    = EnvConfig.footer_app_name;
  $rootScope.footer_email       = EnvConfig.footer_email;
  $rootScope.footer_facebook    = EnvConfig.footer_facebook;
  $rootScope.footer_twitter     = EnvConfig.footer_twitter;
  $rootScope.footer_instagram   = EnvConfig.footer_instagram;
  $rootScope.footer_youtube     = EnvConfig.footer_youtube;
  $rootScope.show_aviso_covid   = EnvConfig.show_aviso_covid;
  $rootScope.show_cuponeras   = EnvConfig.show_cuponeras;
  $rootScope.show_reglas        = EnvConfig.show_reglas;
  $rootScope.signup_nrosocio    = EnvConfig.signup_nrosocio;
  $rootScope.signup_dob         = EnvConfig.signup_dob;
  $rootScope.projectname         = EnvConfig.projectname;
  $rootScope.user = {};
  $rootScope.user.profile_img = "images/Icono_persona.png";
      
  if(EnvConfig.useDefaultStyle){
    Request.get('appconfiguration/?id='+EnvConfig.COMPANY_ID,false,function(response) {
      if(response.status !== 0){
        // toaster.pop('error', "", $translate.instant('api.'+response.description));
        alert('Error loading configuration');
      }else{
        appconfig = response.description.configuration;
        $rootScope.color1=appconfig.color1;
        $rootScope.color2=appconfig.color2;
        $rootScope.color3=appconfig.color3;
        $rootScope.color4=appconfig.color4;
      }
    });



  }

  
  $rootScope.isNumeric = function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  
	$rootScope.$on('$stateChangeSuccess', function(evt, toState, toParams, fromState, fromParams) {

    if(!$rootScope.inArray(toState.name,['signin','recovery','setpwd','signup','welcome/:token','horarios','fronthorarios/:id'])){
      
      var token = $localstorage.get('token');
      var user  = $localstorage.getObject('user');
      var messages  = $localstorage.getObject('messages');
      var user_files = $localstorage.getObject('user_files');
      var user_profile = [];

      if(!token || !user){
        $rootScope.user = {};
        $localstorage.clear();
        $state.go('signin');
      }

      if(user){
        user.nameToShow = user.firstName.split(" ");
        user.nameToShow = user.nameToShow[0];
        $translate.use(user.lang);
        
        var today = new Date();
        var dob = new Date(user.dob+" 12:00:00");
        var today_month = today.getMonth()+1;
        var today_day = today.getDate();
        var dob_month = dob.getMonth()+1;
        var dob_day = dob.getDate();
        
        if(today_day === dob_day && today_month === dob_month){
          user.show_globes = true;
        }else{
          user.show_globes = false;
        }

        if(user_files) {
          user_profile = user_files.filter(function (item) {
            if (item.fileType === "PERFIL") {
              return item;
            }
          });
          if (user_profile.length > 0) {
            user.profile_img = EnvConfig.URL_FILES + user_profile[0].companyId + '/' + user_profile[0].userId + '/' + user_profile[0].fileName;
          }else{
            user.profile_img = "images/Icono_persona.png";
          }
        }
        $rootScope.user = user;
        $rootScope.messages = messages;
      }
    }else{
      var user = {};
      user.profile_img = "images/Icono_persona.png";
      $rootScope.user = user;
    }
    
  });

  $rootScope.logout = function(){
    $localstorage.clear();
    $rootScope.user = {};
    $state.go('signin');
  };

  $rootScope.inArray = function(value, haystack){
    var length = haystack.length;
    for(var i = 0; i < length; i++) {
      if(haystack[i] == value)
      return true;
    }
    return false;
  };

}]);
